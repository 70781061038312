export default [
  {
    component: "CNavGroup",
    name: "PMB",
    to: "/pmb",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/pmb/beranda",
      },
      {
        component: "CNavItem",
        name: "Pengajuan Undur Diri Peserta",
        to: "/pmb/pengunduranDiri",
      },
      {
        component: "CNavItem",
        name: "Daftar Peserta",
        to: "/pmb/daftarPeserta",
      },
      {
        component: "CNavItem",
        name: "Registrasi",
        to: "/pmb/daftarPesertaOnSite",
      },
      {
        component: "CNavGroup",
        name: "Laporan",
        to: "/pmb/Laporan",
        items: [
          {
            component: "CNavItem",
            name: "Laporan Distribusi Agama",
            to: "/pmb/Laporan/Agama",
          },
          {
            component: "CNavItem",
            name: "Laporan Registrasi Per Tanggal",
            to: "/pmb/Laporan/LaporanRegistrasiPerTanggal",
          },
          {
            component: "CNavItem",
            name: "Laporan Pembayaran",
            to: "/pmb/Laporan/laporanPembayaran",
          },
          {
            component: "CNavItem",
            name: "Laporan Pembayaran Cetak BA",
            to: "/pmb/Laporan/laporanPembayaranCetakBA",
          },
          {
            component: "CNavItem",
            name: "Laporan Registrasi On Site per Tanggal",
            to: "/pmb/Laporan/LaporanOnsite",
          },
          {
            component: "CNavItem",
            name: "Laporan Kontak Peserta",
            to: "/pmb/Laporan/LaporanKontakPeserta",
          },
          {
            component: "CNavItem",
            name: "Laporan Peserta PMB",
            to: "/pmb/Laporan/PesertaPmb",
          },
          {
            component: "CNavItem",
            name: "Laporan Distribusi Jenis Kelamin",
            to: "/pmb/Laporan/LaporanDistribusiJK",
          },
          {
            component: "CNavItem",
            name: "Laporan Dokumen Regonsite",
            to: "/pmb/Laporan/DokumenRegonsite",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Pembayaran",
        to: "/pmb/pembayaran",
        items: [
          {
            component: "CNavItem",
            name: "Input Pembayaran",
            to: "/pmb/pembayaran/inputPembayaran",
          },
          {
            component: "CNavItem",
            name: "Beasiswa",
            to: "/pmb/pembayaran/beasiswa",
          },
          {
            component: "CNavItem",
            name: "Konfirmasi Pembayaran",
            to: "/pmb/pembayaran/KonfirmasiPembayaran",
          },
        ],
      },

      {
        component: "CNavGroup",
        name: "Pengaturan",
        to: "/pmb/pengaturan",
        items: [
          {
            component: "CNavItem",
            name: "List Dokumen Peserta PMB",
            to: "/pmb/pengaturan/ListDokumen",
          },
          {
            component: "CNavItem",
            name: "Input pembayaran Finnet",
            to: "/pmb/pengaturan/InputFinnet",
          },
          // {
          //   component: "CNavItem",
          //   name: "Download Manual Pengguna",
          //   to: "/pmb/pengaturan/Manual",
          // },
        ],
      },
      {
        component: "CNavGroup",
        name: "Bantuan",
        to: "/pmb/bantuan",
        items: [
          {
            component: "CNavItem",
            name: "Download Manual Pengguna",
            to: "/pmb/bantuan/Manual",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Finansial Admin Admission",
    to: "/finansial-adm",
    items: [
      {
        component: "CNavGroup",
        name: "Pembayaran Reguler",
        to: "/finansial-adm/pemabayaranreg",
        items: [
          {
            component: "CNavItem",
            name: "Data Pembayaran",
            to: "/finansial-adm/pemabayaranreg/datapembayaran",
          },
        ],
      },
    ],
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
