export default [
  {
    component: "CNavGroup",
    name: "Akun",
    to: "/akun",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/akun/beranda",
      },
      {
        component: "CNavGroup",
        name: "Profilku",
        to: "/akun/profilku",
        items: [
          {
            component: "CNavItem",
            name: "Edit Profil",
            to: "/akun/profilku/editprofil",
          },
          {
            component: "CNavItem",
            name: "Ubah Password",
            to: "/akun/profilku/ubahpassword",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Unduh Berkas",
        to: "/akun/unduhberkas",
      },
      {
        component: "CNavItem",
        name: "Riwayat Login",
        to: "/akun/riwayatlogin",
      },
      {
        component: "CNavGroup",
        name: "Pengawas Ujian",
        to: "/akun/pengawasujian",
        items: [
          {
            component: "CNavItem",
            name: "Pendaftaran Pengawas Ujian",
            to: "/akun/pengawasujian/pendaftaranpengawas",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Ekuivalensi",
    to: "/ekuivalensi",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/ekuivalensi/beranda",
      },
      {
        component: "CNavGroup",
        name: "Ekuivalensi Pindahan",
        to: "/ekuivalensi/pindahan",
        items: [
          {
            component: "CNavItem",
            name: "Proses Ekuivalensi",
            to: "/ekuivalensi/pindahan/proses",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Bantuan",
        to: "/ekuivalensi/bantuan",
        items: [
          {
            component: "CNavItem",
            name: "Proses Ekuivalensi",
            to: "/ekuivalensi/bantuan/manualpengguna",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Finansial",
    to: "/finansial",
    items: [
      {
        component: "CNavGroup",
        name: "Data Transaksi Bank",
        to: "/finansial/datatransaksi",
        items: [
          {
            component: "CNavItem",
            name: "Konfirmasi Transaksi Pembayaran",
            to: "/finansial/datatransaksi/konfirmasi",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Surat Keterangan Bebas Tunggakan",
        to: "/finansial/bebastunggakan",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Nilai",
    to: "/nilai",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Nilai",
        to: "/nilai/beranda",
      },
      {
        component: "CNavGroup",
        name: "KHS",
        to: "/nilai/khs",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Ekivalensi",
            to: "/nilai/khs/lihatekivalensi",
          },
          {
            component: "CNavItem",
            name: "Lihat Nilai",
            to: "/nilai/khs/lihatnilai",
          },
          {
            component: "CNavItem",
            name: "Lihat Nilai Sementara",
            to: "/nilai/khs/lihatnilaisementara",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Statistik Nilai",
        to: "/nilai/statistiknilai",
      },
      {
        component: "CNavItem",
        name: "Pengajuan Status Akademik",
        to: "/nilai/pengajuanstatusakademik",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Pembayaran",
    to: "/pembayaran",
    items: [
      {
        component: "CNavGroup",
        name: "Pembayaran",
        to: "/pembayaran/pembayaran",
        items: [
          {
            component: "CNavItem",
            name: "Detail Pembayaran Mahasiswa",
            to: "/pembayaran/pembayaran/detailpembayaranmahasiswa",
          },
          {
            component: "CNavItem",
            name: "Tagihan",
            to: "/pembayaran/pembayaran/tagihan",
          },
          {
            component: "CNavItem",
            name: "Konfirmasi Pembayaran",
            to: "/pembayaran/pembayaran/konfirmasipembayaran",
          },
          {
            component: "CNavItem",
            name: "Keterlambatan Registrasi Semester",
            to: "/pembayaran/pembayaran/keterlambatanregistrasisemester",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Perkuliahan",
    to: "/perkuliahan",
    items: [
      {
        component: "CNavItem",
        name: "Kehadiran",
        to: "/perkuliahan/kehadiran",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "PMB",
    to: "/pmb",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/pmb/beranda",
      },
      {
        component: "CNavItem",
        name: "Pengajuan Undur Diri Peserta",
        to: "/pmb/pengunduranDiri",
      },
      {
        component: "CNavItem",
        name: "Daftar Peserta",
        to: "/pmb/daftarPeserta",
      },
      {
        component: "CNavItem",
        name: "Registrasi",
        to: "/pmb/daftarPesertaOnSite",
      },
      {
        component: "CNavGroup",
        name: "Laporan",
        to: "/pmb/Laporan",
        items: [
          {
            component: "CNavItem",
            name: "Laporan Distribusi Agama",
            to: "/pmb/Laporan/Agama",
          },
          {
            component: "CNavItem",
            name: "Laporan Registrasi Per Tanggal",
            to: "/pmb/Laporan/LaporanRegistrasiPerTanggal",
          },
          {
            component: "CNavItem",
            name: "Laporan Pembayaran",
            to: "/pmb/Laporan/laporanPembayaran",
          },
          {
            component: "CNavItem",
            name: "Laporan Pembayaran Cetak BA",
            to: "/pmb/Laporan/laporanPembayaranCetakBA",
          },
          {
            component: "CNavItem",
            name: "Laporan Registrasi On Site per Tanggal",
            to: "/pmb/Laporan/LaporanOnsite",
          },
          {
            component: "CNavItem",
            name: "Laporan Kontak Peserta",
            to: "/pmb/Laporan/LaporanKontakPeserta",
          },
          {
            component: "CNavItem",
            name: "Laporan Peserta PMB",
            to: "/pmb/Laporan/PesertaPmb",
          },
          {
            component: "CNavItem",
            name: "Laporan Distribusi Jenis Kelamin",
            to: "/pmb/Laporan/LaporanDistribusiJK",
          },
          {
            component: "CNavItem",
            name: "Laporan Dokumen Regonsite",
            to: "/pmb/Laporan/DokumenRegonsite",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Pembayaran",
        to: "/pmb/pembayaran",
        items: [
          {
            component: "CNavItem",
            name: "Input Pembayaran",
            to: "/pmb/pembayaran/inputPembayaran",
          },
          {
            component: "CNavItem",
            name: "Beasiswa",
            to: "/pmb/pembayaran/beasiswa",
          },
          {
            component: "CNavItem",
            name: "Konfirmasi Pembayaran",
            to: "/pmb/pembayaran/KonfirmasiPembayaran",
          },
        ],
      },

      {
        component: "CNavGroup",
        name: "Pengaturan",
        to: "/pmb/pengaturan",
        items: [
          {
            component: "CNavItem",
            name: "List Dokumen Peserta PMB",
            to: "/pmb/pengaturan/ListDokumen",
          },
          {
            component: "CNavItem",
            name: "Input pembayaran Finnet",
            to: "/pmb/pengaturan/InputFinnet",
          },
          // {
          //   component: "CNavItem",
          //   name: "Download Manual Pengguna",
          //   to: "/pmb/pengaturan/Manual",
          // },
        ],
      },
      {
        component: "CNavGroup",
        name: "Bantuan",
        to: "/pmb/bantuan",
        items: [
          {
            component: "CNavItem",
            name: "Download Manual Pengguna",
            to: "/pmb/bantuan/Manual",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Presensi",
    to: "/presensi",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Presensi",
        to: "/presensi/beranda",
      },
      {
        component: "CNavItem",
        name: "RFID Log",
        to: "/presensi/rfidlog",
      },
      {
        component: "CNavItem",
        name: "Kehadiran",
        to: "/presensi/kehadiran",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "TA/PA",
    to: "/ta_pa",
    items: [
      {
        component: "CNavItem",
        name: "Beranda TA/PA",
        to: "/ta_pa/beranda",
      },
      {
        component: "CNavGroup",
        name: "Daftar TA/PA",
        to: "/ta_pa/daftarta_pa",
        items: [
          {
            component: "CNavItem",
            name: "Status TA/PA Mahasiswa",
            to: "/ta_pa/daftarta_pa/statusta_pamahasiswa",
          },
          {
            component: "CNavItem",
            name: "Daftar TA/PA yang Sedang Berlangsung",
            to: "/ta_pa/daftarta_pa/daftarta_payangsedangberlangsung",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Jadwal",
        to: "/ta_pa/jadwal",
      },
      {
        component: "CNavGroup",
        name: "Bantuan",
        to: "/ta_pa/bantuan",
        items: [
          {
            component: "CNavItem",
            name: "Manual Pengguna",
            to: "/ta_pa/bantuan/manualpengguna",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "TAK Mahasiswa",
    to: "/tak",
    items: [
      {
        component: "CNavItem",
        name: "Beranda TAK",
        to: "/tak/beranda",
      },
      {
        component: "CNavItem",
        name: "Input TAK Mahasiswa",
        to: "/tak/inputtakmahasiswa",
      },
      {
        component: "CNavItem",
        name: "Daftar TAK Mahasiswa",
        to: "/tak/daftartakmahasiswa",
      },
      {
        component: "CNavItem",
        name: "TAK Proses",
        to: "/tak/takproses",
      },
      {
        component: "CNavItem",
        name: "TAK Usang",
        to: "/tak/takusang",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Registrasi",
    to: "/registrasi",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Registrasi",
        to: "/registrasi/beranda",
      },
      {
        component: "CNavGroup",
        name: "Registrasi",
        to: "/registrasi/reg",
        items: [
          {
            component: "CNavItem",
            name: "Tagihan Register",
            to: "/registrasi/reg/tagihanregis",
          },
          {
            component: "CNavItem",
            name: "Registrasi Mata Kuliah",
            to: "/registrasi/reg/registrasimatkul",
          },
          {
            component: "CNavItem",
            name: "Status Registrasi Mahasiswa",
            to: "/registrasi/reg/statusregis",
          },
          {
            component: "CNavItem",
            name: "Arsip KSM",
            to: "/registrasi/reg/arsipksm",
          },
          {
            component: "CNavItem",
            name: "History Proses Registrasi",
            to: "/registrasi/reg/historyregis",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Jadwal",
        to: "/registrasi/jadwal",
        items: [
          {
            component: "CNavItem",
            name: "Jadwal Mahasiswa",
            to: "/registrasi/jadwal/jadwalmhs",
          },
          {
            component: "CNavItem",
            name: "Jadwal Ujian Mahasiswa",
            to: "/registrasi/jadwal/jadwalujianmhs",
          },
          {
            component: "CNavItem",
            name: "Kalender Akademik",
            to: "/registrasi/jadwal/kalenderakademik",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Bantuan",
        to: "/registrasi/bantuan",
        items: [
          {
            component: "CNavItem",
            name: "Manual Pengguna",
            to: "/registrasi/bantuan",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Perwalian",
    to: "/kemahasiswaan",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Perwalian",
        to: "/perwalian/beranda",
      },
      {
        component: "CNavGroup",
        name: "Konsultasi",
        to: "/perwalian/konsultasi",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Pesan",
            to: "/perwalian/konsultasi/lihatpesan",
          },
          {
            component: "CNavItem",
            name: "Kirim Pesan",
            to: "/perwalian/konsultasi/kirimpesan",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Data Akademik",
        to: "/perwalian/dataakademik",
      },
      {
        component: "CNavItem",
        name: "Panduan",
        to: "/perwalian/panduan",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Wisuda",
    to: "/wisuda",
    items: [
      {
        component: "CNavItem",
        name: "Pendaftaran",
        to: "/wisuda/pendaftaran",
      },
      {
        component: "CNavItem",
        name: "Beranda Wisuda",
        to: "/wisuda/beranda",
      },
    ],
  },
  {
    name: "Kurikulum",
    to: "/kurikulum",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Kurikulum",
        to: "/kurikulum/beranda",
      },
      {
        component: "CNavGroup",
        name: "Silabus",
        to: "/kurikulum/silabus",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Pesan",
            to: "/kurikulum/silabus/lihatrps",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Kemahasiswaan",
    to: "/kemahasiswaan",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Kemahasiswaan",
        to: "/kemahasiswaan/beranda",
      },
      {
        component: "CNavGroup",
        name: "Manajemen Lomba",
        to: "/kemahasiswaan/manajemenlomba",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Lomba",
            to: "/kemahasiswaan/manajemenlomba/lihatlomba",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Manajemen Proposal",
        to: "/kemahasiswaan/manajemenproposal",
        items: [
          {
            component: "CNavItem",
            name: "Proposal Ormawa",
            to: "/kemahasiswaan/manajemenproposal/ormawa",
          },
          {
            component: "CNavItem",
            name: "Proposal Lomba",
            to: "/kemahasiswaan/manajemenproposal/lomba",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "SKPI",
    to: "/skpi",
    items: [
      {
        component: "CNavItem",
        name: "TAK & Informasi Lainnya",
        to: "/skpi/takinfo",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Survey",
    to: "/survey",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/survey/beranda",
      },
      {
        component: "CNavItem",
        name: "Kuesioner",
        to: "/survey/kuesioner",
      },
      {
        component: "CNavItem",
        name: "Bantuan",
        to: "/survey/bantuan",
      },
    ],
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
