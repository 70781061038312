import { h, resolveComponent } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";

import DefaultLayout from "@/layouts/DefaultLayout";
import LayoutCard from "@/layouts/LayoutCard";
import NotFound from "@/views/pages/Page404";

const routes = [
  // 404
  { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound },

  {
    path: "/",
    name: "Home",
    component: DefaultLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
      },
      {
        path: "/akun",
        name: "Akun",
        component: LayoutCard,
        redirect: "/akun/beranda",
        children: [
          {
            path: "/akun/beranda",
            name: "Beranda Akun",
            component: () => import("@/views/akun/Beranda.vue"),
          },
          {
            path: "/akun/profilku",
            name: "Profilku",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/akun/profilku/editprofil",
            children: [
              {
                path: "/akun/profilku/editprofil",
                name: "Edit Profil",
                component: () => import("@/views/akun/EditProfil.vue"),
              },
              {
                path: "/akun/profilku/ubahpassword",
                name: "Ubah Password",
                component: () => import("@/views/akun/UbahPassword.vue"),
              },
            ],
          },
          {
            path: "/akun/unduhberkas",
            name: "Unduh Berkas",
            component: () => import("@/views/akun/UnduhBerkas.vue"),
          },
          {
            path: "/akun/riwayatlogin",
            name: "Riwayat Login",
            component: () => import("@/views/akun/RiwayatLogin.vue"),
          },
          {
            path: "/akun/pengawasujian",
            name: "Pengawas Ujian",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/akun/pengawasujian/pendaftaranpengawas",
            children: [
              {
                path: "/akun/pengawasujian/pendaftaranpengawas",
                name: "Pendaftaran Pengawas Ujian",
                component: () => import("@/views/akun/PendaftaranPengawas.vue"),
              },
            ],
          },
          {
            path: "/akun/managementuser",
            name: "Management User",
            component: () => import("@/views/akun/ManagementUser.vue"),
          },
        ],
      },

      {
        path: "/akun_dosen",
        name: "Akun_Dosen",
        component: LayoutCard,
        redirect: "/akun_dosen",
        children: [
          {
            path: "/akun_dosen/profilKu",
            name: "ProfilKu",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/akun_dosen/profilku/ubahpassword",
            children: [
              {
                path: "/akun_dosen/profilku/ubahpassword",
                name: "Ubah Password Dosen",
                component: () => import("@/views/Dosen/profil/UbahPas.vue"),
              },
            ],
          },
          {
            path: "/akun_dosen/riwayatlogin",
            name: "Riwayat Login Dosen",
            component: () =>
              import("@/views/Dosen/akun_dosen/RiwayatLogin.vue"),
          },
        ],
      },
      {
        path: "/ekuivalensi",
        name: "Ekuivalensi",
        component: LayoutCard,
        redirect: "/ekuivalensi/beranda",
        children: [
          {
            path: "/ekuivalensi/beranda",
            name: "Beranda Ekuivalensi",
            component: () => import("@/views/ekuivalensi/Beranda.vue"),
          },
          {
            path: "/ekuivalensi/bantuan",
            name: "Bantuan Ekuivalensi",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/ekuivalensi/bantuan/manualpengguna",
            children: [
              {
                path: "/ekuivalensi/bantuan/manualpengguna",
                name: "Manual Pengguna Ekuivalensi",
                component: () =>
                  import("@/views/ekuivalensi/ManualPengguna.vue"),
              },
            ],
          },
          {
            path: "/ekuivalensi/pindahan",
            name: "Ekuivalensi Pindahan",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/ekuivalensi/pindahan/proses",
            children: [
              {
                path: "/ekuivalensi/pindahan/proses",
                name: "Proses Ekuivalensi",
                component: () =>
                  import("@/views/ekuivalensi/ProsesEkuivalensi.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/finansial",
        name: "Finansial",
        component: LayoutCard,
        redirect: "/finansial/datatransaksi",
        children: [
          {
            path: "/finansial/datatransaksi",
            name: "Data Transaksi Bank",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/finansial/datatransaksi/konfirmasi",
            children: [
              {
                path: "/finansial/datatransaksi/konfirmasi",
                name: "Konfirmasi Transaksi Pembayaran",
                component: () =>
                  import("@/views/finansial/KonfirmasiTransaksi.vue"),
              },
            ],
          },
          {
            path: "/finansial/bebastunggakan",
            name: "Surat Keterangan Bebas Tunggakan",
            component: () => import("@/views/finansial/BebasTunggakan.vue"),
          },
        ],
      },
      {
        path: "/nilai",
        name: "Nilai",
        component: LayoutCard,
        redirect: "/nilai/beranda",
        children: [
          {
            path: "/nilai/beranda",
            name: "Beranda Nilai",
            component: () => import("@/views/nilai/Beranda.vue"),
          },
          {
            path: "/nilai/khs",
            name: "KHS",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/nilai/khs/lihatekivalensi",
            children: [
              {
                path: "/nilai/khs/lihatekivalensi",
                name: "Lihat Ekivalensi",
                component: () => import("@/views/nilai/LihatEkivalensi.vue"),
              },
              {
                path: "/nilai/khs/lihatnilai",
                name: "Lihat Nilai",
                component: () => import("@/views/nilai/LihatNilai.vue"),
              },
              {
                path: "/nilai/khs/lihatnilaisementara",
                name: "Lihat Nilai Sementara",
                component: () =>
                  import("@/views/nilai/LihatNilaiSementara.vue"),
              },
            ],
          },
          {
            path: "/nilai/statistiknilai",
            name: "Statistik Nilai",
            component: () => import("@/views/nilai/StatistikNilai.vue"),
          },
          {
            path: "/nilai/pengajuanstatusakademik",
            name: "Pengajuan Status Akademik",
            component: () =>
              import("@/views/nilai/PengajuanStatusAkademik.vue"),
          },
        ],
      },
      {
        path: "/nilaidosen",
        name: "Nilai Dosen",
        component: LayoutCard,
        redirect: "/nilaidosen/berandanilai",
        children: [
          {
            path: "/nilaidosen/berandanilai",
            name: "Beranda_Nilai ",
            component: () => import("@/views/Nilai_Dosen/Beranda.vue"),
          },
          {
            path: "/nilaidosen/riwayatinput",
            name: "Riwayat Input Nilai ",
            component: () => import("@/views/Nilai_Dosen/Riwayat.vue"),
          },
          {
            path: "/nilaidosen/listmatkul",
            name: "List Mata Kuliah ",
            component: () => import("@/views/Nilai_Dosen/ListMatkul.vue"),
          },
          {
            path: "/nilaidosen/inputnilai/:id",
            name: "Input Nilai ",
            component: () => import("@/views/Nilai_Dosen/InputNilai.vue"),
          },
        ],
      },
      {
        path: "/pembayaran",
        name: "Pembayaran",
        component: LayoutCard,
        redirect: "/pembayaran/pembayaran",
        children: [
          {
            path: "/pembayaran/pembayaran",
            name: "Pembayaran",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/pembayaran/pembayaran",
            children: [
              {
                path: "/pembayaran/pembayaran/detailpembayaranmahasiswa",
                name: "Detail Pembayaran Mahasiswa",
                component: () =>
                  import(
                    "@/views/pembayaran/pembayaran/DetailPembayaranMahasiswa.vue"
                  ),
              },
              {
                path: "/pembayaran/pembayaran/tagihan",
                name: "Tagihan",
                component: () =>
                  import("@/views/pembayaran/pembayaran/Tagihan.vue"),
              },
              {
                path: "/pembayaran/pembayaran/invoice",
                name: "Invoice",
                component: () =>
                  import("@/views/pembayaran/pembayaran/Invoice.vue"),
              },
              {
                path: "/pembayaran/pembayaran/konfirmasipembayaran",
                name: "Konfirmasi Pembayaran",
                component: () =>
                  import(
                    "@/views/pembayaran/pembayaran/KonfirmasiPembayaran.vue"
                  ),
              },
              {
                path: "/pembayaran/pembayaran/keterlambatanregistrasisemester",
                name: "Keterlambatan Registrasi Semester",
                component: () =>
                  import(
                    "@/views/pembayaran/pembayaran/KeterlambatanRegistrasiSemester.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "/perkuliahan",
        name: "Perkuliahan",
        component: LayoutCard,
        redirect: "/perkuliahan",
        children: [
          {
            path: "/perkuliahan/kehadiran",
            name: "Kehadiran",
            component: () => import("@/views/perkuliahan/Kehadiran.vue"),
          },
        ],
      },
      {
        path: "/pmb",
        name: "PMB AA",
        component: LayoutCard,
        redirect: "/pmb",
        children: [
          {
            path: "/pmb/beranda",
            name: "Beranda PMB",
            component: () => import("@/views/PMB/Beranda.vue"),
          },
          {
            path: "/pmb/pengunduranDiri",
            name: "Pengajuan Undur Diri Peserta",
            component: () => import("@/views/PMB/pengunduranDiri.vue"),
          },
          {
            path: "/pmb/daftarPeserta",
            name: "Daftar Peserta",
            component: () => import("@/views/PMB/daftarPeserta.vue"),
          },
          {
            path: "/pmb/daftarPesertaOnSite",
            name: "Daftar Peserta Registrasi On Site",
            component: () => import("@/views/PMB/daftarPesertaOnSite.vue"),
          },

          {
            path: "/pmb/pembayaran",
            name: "Pembayaran PMB",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/pmb/pembayaran",
            children: [
              {
                path: "/pmb/pembayaran/beasiswa",
                name: "Beasiswa PMB",
                component: () => import("@/views/PMB/pembayaran/Beasiswa.vue"),
              },
              {
                path: "/pmb/pembayaran/InputPembayaran",
                name: "Input Pembayaran",
                component: () =>
                  import("@/views/PMB/pembayaran/InputPembayaran.vue"),
              },
              {
                path: "/pmb/pembayaran/KonfirmasiPembayaran",
                name: "Konfirmasi Pembayaran PMB",
                component: () =>
                  import("@/views/PMB/pembayaran/KonfirmasiPembayaran.vue"),
              },
            ],
          },
          {
            path: "/pmb/Laporan",
            name: "Laporan AA",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/pmb/laporan",
            children: [
              {
                path: "/pmb/Laporan/Agama",
                name: "Laporan Distribusi Agama AA",
                component: () => import("@/views/PMB/Laporan/Agama.vue"),
              },
              {
                path: "/pmb/Laporan/LaporanRegistrasiPerTanggal",
                name: "Laporan Registrasi Per Tanggal AA",
                component: () =>
                  import("@/views/PMB/Laporan/LaporanRegistrasiPerTanggal.vue"),
              },
              {
                path: "/pmb/Laporan/laporanPembayaran",
                name: "Laporan Pembayaran PMB",
                component: () =>
                  import("@/views/PMB/Laporan/laporanPembayaran.vue"),
              },
              {
                path: "/pmb/Laporan/laporanPembayaranCetakBA",
                name: "Laporan Pemabayaran Cetak BA",
                component: () =>
                  import("@/views/PMB/Laporan/laporanPembayaranCetakBA.vue"),
              },
              {
                path: "/pmb/Laporan/LaporanOnsite",
                name: "Laporan Registrasi On Site per Tanggal AA",
                component: () =>
                  import("@/views/PMB/Laporan/LaporanOnsite.vue"),
              },
              {
                path: "/pmb/Laporan/LaporanKontakPeserta",
                name: "Laporan Kontak Peserta AA",
                component: () =>
                  import("@/views/PMB/Laporan/LaporanKontak.vue"),
              },
              {
                path: "/pmb/Laporan/PesertaPmb",
                name: "Laporan Peserta PMB AA",
                component: () => import("@/views/PMB/Laporan/PesertaPmb.vue"),
              },
              {
                path: "/pmb/Laporan/LaporanDistribusiJK",
                name: "Laporan Distribusi Jenis Kelamin AA",
                component: () =>
                  import("@/views/PMB/Laporan/LaporanDistribusiJK.vue"),
              },
              {
                path: "/pmb/Laporan/DokumenRegonsite",
                name: "Laporan Dokumen Regonsite AA",
                component: () =>
                  import("@/views/PMB/Laporan/DokumenRegonsite.vue"),
              },
            ],
          },

          {
            path: "/pmb/Pengaturan",
            name: "Pengaturan",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/pmb/Pengaturan",
            children: [
              {
                path: "/pmb/Pengaturan/ListDokumen",
                name: "List Dokumen Peserta PMB",
                component: () =>
                  import("@/views/PMB/Pengaturan/ListDokumen.vue"),
              },
              {
                path: "/pmb/Pengaturan/InputFinnet",
                name: "Input pembayaran Finnet",
                component: () =>
                  import("@/views/PMB/Pengaturan/InputFinnet.vue"),
              },
              {
                path: "/pmb/Pengaturan/Manual",
                name: "Download Manual Pengguna",
                component: () => import("@/views/PMB/Bantuan/Manual.vue"),
              },
            ],
          },
          {
            path: "/pmb/bantuan",
            name: "Bantuan PMB",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/pmb/bantuan",
            children: [
              {
                path: "/pmb/bantuan/Manual",
                name: "Download Manual Pengguna",
                component: () => import("@/views/PMB/Bantuan/Manual.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/ppm",
        name: "PPM",
        component: LayoutCard,
        redirect: "/ppm",
        children: [
          {
            path: "/ppm/acara",
            name: "Acara",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/ppm/acara/jurnalsaya",
            children: [
              {
                path: "/ppm/acara/jurnalsaya",
                name: "Jurnal Saya",
                component: () => import("@/views/PPM/acara/Jurnal.vue"),
              },
              {
                path: "/ppm/acara/penelitian",
                name: "Penelitian",
                component: () => import("@/views/PPM/acara/Penelitian.vue"),
              },
            ],
          },
          {
            path: "/ppm/dokumen",
            name: "Dokumen",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/ppm/dokumen/dokumenpendukung",
            children: [
              {
                path: "/ppm/dokumen/dokumenpendukung",
                name: "Dokumen Pendukung ",
                component: () => import("@/views/PPM/dokumen/Dokumen.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/presensi",
        name: "Presensi",
        component: LayoutCard,
        redirect: "/presensi/beranda",
        children: [
          {
            path: "/presensi/beranda",
            name: "Beranda Presensi",
            component: () => import("@/views/presensi/Beranda.vue"),
          },
          {
            path: "/presensi/rfidlog",
            name: "RFID Log",
            component: () => import("@/views/presensi/RFIDLog.vue"),
          },
          {
            path: "/presensi/kehadiran",
            name: "Kehadiran2",
            component: () => import("@/views/perkuliahan/Kehadiran.vue"),
          },
        ],
      },
      {
        path: "/ta_pa",
        name: "TA/PA",
        component: LayoutCard,
        redirect: "/ta_pa/beranda",
        children: [
          {
            path: "/ta_pa/beranda",
            name: "Beranda TA/PA",
            component: () => import("@/views/TA_PA/Beranda.vue"),
          },
          {
            path: "/ta_pa/daftarta_pa",
            name: "Daftar TA/PA",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/ta_pa/daftarta_pa/statusta_pamahasiswa",
            children: [
              {
                path: "/ta_pa/daftarta_pa/statusta_pamahasiswa",
                name: "Status TA/PA Mahasiswa",
                component: () =>
                  import("@/views/TA_PA/DaftarTA_PA/StatusTA_PAMahasiswa.vue"),
              },
              {
                path: "/ta_pa/daftarta_pa/finaltaskstudentreference",
                name: "Final Task Student Reference",
                component: () =>
                  import(
                    "@/views/TA_PA/DaftarTA_PA/FinalTaskStudentReference.vue"
                  ),
              },
              {
                path: "/ta_pa/daftarta_pa/daftarta_payangsedangberlangsung",
                name: "Daftar TA/PA yang Sedang Berlangsung",
                component: () =>
                  import(
                    "@/views/TA_PA/DaftarTA_PA/DaftarTA_PAyangSedangBerlangsung.vue"
                  ),
              },
              {
                path: "/ta_pa/daftarta_pa/finaltasksidangrevisi",
                name: "Final Task Sidang Revisi",
                component: () =>
                  import("@/views/TA_PA/DaftarTA_PA/FinalTaskSidangRevisi.vue"),
              },
            ],
          },
          {
            path: "/ta_pa/jadwal",
            name: "Jadwal TA/PA",
            component: () => import("@/views/TA_PA/Jadwal.vue"),
          },
          {
            path: "/ta_pa/bantuan",
            name: "Bantuan TA/PA",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/ta_pa/bantuan/manualpengguna",
            children: [
              {
                path: "/ta_pa/bantuan/manualpengguna",
                name: "Manual Pengguna TA/PA",
                component: () =>
                  import("@/views/TA_PA/Bantuan/ManualPengguna.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/tapa_dos",
        name: "TA/PA Dosen",
        component: LayoutCard,
        redirect: "/tapa_dos/beranda",
        children: [
          {
            path: "/tapa_dos/beranda",
            name: "Beranda TA/PA Dosen",
            component: () => import("@/views/TAPA_dosen/Beranda.vue"),
          },
          {
            path: "/tapa_dos/daftarmahasiswabimbingansudahsidang",
            name: "Daftar Mahasiswa Bimbingan Sudah Sidang Dosen",
            component: () =>
              import(
                "@/views/TAPA_dosen/DaftarMahasiswaBimbinganSudahSidang.vue"
              ),
          },
          {
            path: "/tapa_dos/daftarmahasiswadiuji",
            name: "Daftar Mahasiswa Bimbingan Diuji Dosen",
            component: () =>
              import("@/views/TAPA_dosen/DaftarMahasiswaDiuji.vue"),
          },
          {
            path: "/tapa_dos/daftarmahasiswabimbingan",
            name: "Daftar Mahasiswa Bimbingan Dosen",
            component: () =>
              import("@/views/TAPA_dosen/DaftarMahasiswaBimbingan.vue"),
          },
          {
            path: "/tapa_dos/jadwaltapamahasiswa",
            name: "Jadwal TAPA Mahasiswa Dosen",
            component: () =>
              import("@/views/TAPA_dosen/JadwalTAPAMahasiswa.vue"),
          },
          {
            path: "/tapa_dos/referensi",
            name: "Referensi Dosen",
            component: () => import("@/views/TAPA_dosen/Referensi.vue"),
          },
        ],
      },
      {
        path: "/tak",
        name: "TAK Mahasiswa",
        component: LayoutCard,
        redirect: "/tak/beranda",
        children: [
          {
            path: "/tak/beranda",
            name: "Beranda TAK",
            component: () => import("@/views/tak/Beranda.vue"),
          },
          {
            path: "/tak/inputtakmahasiswa",
            name: "Input TAK Mahasiswa",
            component: () => import("@/views/tak/InputTakMahasiswa.vue"),
          },
          {
            path: "/tak/daftartakmahasiswa",
            name: "Daftar TAK Mahasiswa",
            component: () => import("@/views/tak/DaftarTakMahasiswa.vue"),
          },
          {
            path: "/tak/takproses",
            name: "TAK Proses",
            component: () => import("@/views/tak/TakProses.vue"),
          },
          {
            path: "/tak/takusang",
            name: "TAK Usang",
            component: () => import("@/views/tak/TakUsang.vue"),
          },
        ],
      },
      {
        path: "/registrasi",
        name: "Registrasi",
        component: LayoutCard,
        redirect: "/registrasi/beranda",
        children: [
          {
            path: "/registrasi/beranda",
            name: "Beranda Registrasi",
            component: () => import("@/views/registrasi/Beranda.vue"),
          },
          {
            path: "/registrasi/reg",
            name: "Registrasi",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasi/reg/tagihanregis",
            children: [
              {
                path: "/registrasi/reg/tagihanregis",
                name: "Tagihan Registrasi",
                component: () =>
                  import("@/views/registrasi/TagihanRegistrasi.vue"),
              },
              {
                path: "/registrasi/reg/detailpembayaran/:id",
                name: "Detail Pembayaran",
                component: () =>
                  import("@/views/registrasi/DetailPembayaran.vue"),
              },
              {
                path: "/registrasi/reg/registrasimatkul",
                name: "Registrasi Mata Kuliah",
                component: () => import("@/views/registrasi/KRS.vue"),
              },
              {
                path: "/registrasi/reg/statusregis",
                name: "Status Registrasi",
                component: () =>
                  import("@/views/registrasi/StatusRegistrasi.vue"),
              },
              {
                path: "/registrasi/reg/arsipksm",
                name: "Arsip KSM",
                component: () => import("@/views/registrasi/ArsipKSM.vue"),
              },
              {
                path: "/registrasi/reg/historyregis",
                name: "History Proses Registrasi",
                component: () =>
                  import("@/views/registrasi/HistoryProsesRegistrasi.vue"),
              },
            ],
          },
          {
            path: "/registrasi/jadwal",
            name: "Jadwal",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasi/jadwal/jadwalmhs",
            children: [
              {
                path: "/registrasi/jadwal/jadwalmhs",
                name: "Jadwal Mahasiswa",
                component: () =>
                  import("@/views/registrasi/JadwalMahasiswa.vue"),
              },
              {
                path: "/registrasi/jadwal/jadwalujianmhs",
                name: "Jadwal Ujian Mahasiswa",
                component: () =>
                  import("@/views/registrasi/JadwalUjianMahasiswa.vue"),
              },
              {
                path: "/registrasi/jadwal/kalenderakademik",
                name: "Kalender Akademik",
                component: () =>
                  import("@/views/registrasi/KalenderAkademik.vue"),
              },
            ],
          },
          {
            path: "/registrasi/bantuan",
            name: "Bantuan Registrasi",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasi/bantuan/manualpengguna",
            children: [
              {
                path: "/registrasi/bantuan/manualpengguna",
                name: "Registrasi Manual Pengguna",
                component: () =>
                  import("@/views/registrasi/ManualPengguna.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/registrasidosen",
        name: "Registrasi Dosen",
        component: LayoutCard,
        redirect: "/registrasidosen",
        children: [
          {
            path: "/registrasidosen/berandaregdosen",
            name: "Beranda Registrasi Dosen",
            component: () => import("@/views/Registrasi_Dosen/Beranda_Reg.vue"),
          },
          {
            path: "/registrasidosen/jadwal",
            name: "Jadwal Registrasi Dosen",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasidosen/jadwal",
            children: [
              {
                path: "/registrasidosen/jadwal/lihatjadwal",
                name: "Lihat Jadwal Mahasiswa Keseluruhan",
                component: () =>
                  import("@/views/Registrasi_Dosen/Jadwal/Keseluruhan.vue"),
              },
              {
                path: "/registrasidosen/jadwal/ketersediaandosen",
                name: "Ketersediaan Jadwal Dosen",
                component: () =>
                  import(
                    "@/views/Registrasi_Dosen/Jadwal/KetersediaanDosen.vue"
                  ),
              },
              {
                path: "/registrasidosen/jadwal/rencanamengajar",
                name: "Rencana Mengajar Dosen",
                component: () =>
                  import("@/views/Registrasi_Dosen/Jadwal/RencanaMengajar.vue"),
              },
              {
                path: "/registrasidosen/jadwal/lihatjadwalujian",
                name: "Lihat Jadwal Ujian",
                component: () =>
                  import("@/views/Registrasi_Dosen/Jadwal/LihatJadwal.vue"),
              },
              {
                path: "/registrasidosen/jadwal/jadwalujianmahasiswa",
                name: "Jadwal Ujian Mahasiswa Dosen",
                component: () =>
                  import("@/views/Registrasi_Dosen/Jadwal/JadwalUjian.vue"),
              },
              {
                path: "/registrasidosen/jadwal/jadwaldosen",
                name: "Jadwal Dosen Registrasi",
                component: () =>
                  import("@/views/Registrasi_Dosen/Jadwal/JadwalDosen.vue"),
              },
              {
                path: "/registrasidosen/jadwal/kalenderak",
                name: "Kalender Akademik Dosen",
                component: () =>
                  import("@/views/Registrasi_Dosen/Jadwal/KalenderAk.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/perwalian",
        name: "Perwalian",
        component: LayoutCard,
        redirect: "/perwalian/beranda",
        children: [
          {
            path: "/perwalian/beranda",
            name: "Beranda Perwalian",
            component: () => import("@/views/perwalian/Beranda.vue"),
          },
          {
            path: "/perwalian/konsultasi",
            name: "Konsultasi",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/perwalian/konsultasi/lihatpesan",
            children: [
              {
                path: "/perwalian/konsultasi/lihatpesan",
                name: "Lihat Pesan",
                component: () => import("@/views/perwalian/LihatPesan.vue"),
              },
              {
                path: "/perwalian/konsultasi/kirimpesan",
                name: "Kirim Pesan",
                component: () => import("@/views/perwalian/KirimPesan.vue"),
              },
            ],
          },
          {
            path: "/perwalian/dataakademik",
            name: "Data Akademik",
            component: () => import("@/views/perwalian/DataAkademik.vue"),
          },
          {
            path: "/perwalian/panduan",
            name: "Panduan",
            component: () => import("@/views/perwalian/Panduan.vue"),
          },
        ],
      },
      {
        path: "/wisuda",
        name: "Wisuda",
        component: LayoutCard,
        redirect: "/wisuda/pendaftaran",
        children: [
          {
            path: "/wisuda/pendaftaran",
            name: "Pendaftaran",
            component: () => import("@/views/wisuda/Pendaftaran.vue"),
          },
          {
            path: "/wisuda/beranda",
            name: "Beranda Wisuda",
            component: () => import("@/views/wisuda/Beranda.vue"),
          },
        ],
      },
      {
        path: "/kurikulum",
        name: "Kurikulum",
        component: LayoutCard,
        redirect: "/kurikulum/beranda",
        children: [
          {
            path: "/kurikulum/beranda",
            name: "Beranda Kurikulum",
            component: () => import("@/views/kurikulum/Beranda.vue"),
          },
          {
            path: "/kurikulum/silabus",
            name: "Silabus",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/kurikulum/silabus/lihatrps",
            children: [
              {
                path: "/kurikulum/silabus/lihatrps",
                name: "Lihat RPS",
                component: () => import("@/views/kurikulum/LihatRPS.vue"),
              },
              {
                path: "/kurikulum/silabus/lihatrps/gbpp/:id",
                name: "GBPP",
                component: () => import("@/views/kurikulum/GBPP.vue"),
              },
              {
                path: "/kurikulum/silabus/lihatrps/sap/:id",
                name: "SAP",
                component: () => import("@/views/kurikulum/SAP.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/kurikulumdosen",
        name: "Kurikulum Dosen",
        component: LayoutCard,
        redirect: "/kurikulumdosen",
        children: [
          {
            path: "/kurikulumdosen/berandadosen",
            name: "Beranda Dosen",
            component: () =>
              import("@/views/Kurikulum_Dosen/Beranda_Dosen.vue"),
          },
        ],
      },
      {
        path: "/skpi",
        name: "SKPI",
        component: LayoutCard,
        redirect: "/skpi/takinfo",
        children: [
          {
            path: "/skpi/takinfo",
            name: "TAK & Informasi Lainnya",
            component: () => import("@/views/SKPI/TAK&InfoLainnya.vue"),
          },
        ],
      },
      {
        path: "/kemahasiswaan",
        name: "KEMAHASISWAAN",
        component: LayoutCard,
        redirect: "/kemahasiswaan/beranda",
        children: [
          {
            path: "/kemahasiswaan/beranda",
            name: "Beranda Kemahasiswaan",
            component: () => import("@/views/kemahasiswaan/Beranda.vue"),
          },
          {
            path: "/kemahasiswaan/manajemenlomba",
            name: "Manajemen Lomba",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/kemahasiswaan/manajemenlomba/lihatlomba",
            children: [
              {
                path: "/kemahasiswaan/manajemenlomba/lihatlomba",
                name: "Lihat Lomba",
                component: () => import("@/views/kemahasiswaan/LihatLomba.vue"),
              },
            ],
          },
          {
            path: "/kemahasiswaan/manajemenproposal",
            name: "Manajemen Proposal",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/kemahasiswaan/manajemenproposal/ormawa",
            children: [
              {
                path: "/kemahasiswaan/manajemenproposal/ormawa",
                name: "Proposal Ormawa",
                component: () =>
                  import("@/views/kemahasiswaan/ProposalOrmawa.vue"),
              },
              {
                path: "/kemahasiswaan/manajemenproposal/lomba",
                name: "Proposal Lomba",
                component: () =>
                  import("@/views/kemahasiswaan/ProposalLomba.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/survey",
        name: "Survey",
        component: LayoutCard,
        redirect: "/survey/beranda",
        children: [
          {
            path: "/survey/beranda",
            name: "Beranda",
            component: () => import("@/views/survey/Beranda.vue"),
          },
          {
            path: "/survey/kuesioner",
            name: "Kuesioner",
            component: () => import("@/views/survey/Kuesioner.vue"),
          },
          {
            path: "/survey/bantuan",
            name: "Bantuan",
            component: () => import("@/views/survey/Bantuan.vue"),
          },
        ],
      },
      {
        path: "/finansial-adm",
        name: "Finansial Admin Admission",
        component: LayoutCard,
        redirect: "/finansial-adm/pemabayaranreg",
        children: [
          {
            path: "/finansial-adm/pemabayaranreg",
            name: "Pembayaran Reguler Adm",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/finansial-adm/pemabayaranreg/datapembayaran",
            children: [
              {
                path: "/finansial-adm/pemabayaranreg/datapembayaran",
                name: "Data Pembayaran Adm",
                component: () =>
                  import("@/views/finansial_admin/DataPembayaran.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/finansial-keu",
        name: "Finansial KEU",
        component: LayoutCard,
        redirect: "/finansial-keu/daftardetaillaporan/financelaporandetailmaba",
        children: [
          {
            path: "/finansial-keu/laporan",
            name: "Laporan",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/finansial-keu/laporan/logva",
            children: [
              {
                path: "/finansial-keu/laporan/logva",
                name: "Log VA",
                component: () =>
                  import("@/views/finansial_keu/laporan/LogVA.vue"),
              },
              {
                path: "/finansial-keu/laporan/laporankeuanganbeasiswa",
                name: "Laporan Keuangan Beasiswa",
                component: () =>
                  import(
                    "@/views/finansial_keu/laporan/LaporanKeuanganBeasiswa.vue"
                  ),
              },
              {
                path: "/finansial-keu/laporan/laporankeuanganpmb",
                name: "Laporan Keuangan PMB",
                component: () =>
                  import(
                    "@/views/finansial_keu/laporan/LaporanKeuanganPMB.vue"
                  ),
              },
              {
                path: "/finansial-keu/laporan/laporanpiutangmhs",
                name: "Laporan Piutang Mahasiswa",
                component: () =>
                  import(
                    "@/views/finansial_keu/laporan/LaporanPiutangMahasiswa.vue"
                  ),
              },
            ],
          },
          {
            path: "/finansial-keu/daftardetaillaporan",
            name: "Daftar Detail Laporan",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect:
              "/finansial-keu/daftardetaillaporan/financelaporandetailmaba",
            children: [
              {
                path: "/finansial-keu/daftardetaillaporan/financelaporandetailmaba",
                name: "Finance Laporan Detail MABA",
                component: () =>
                  import(
                    "@/views/finansial_keu/daftardetaillaporan/FinanceLaporanDetailMABA.vue"
                  ),
              },
              {
                path: "/finansial-keu/daftardetaillaporan/financelaporandetailmala",
                name: "Finance Laporan Detail MALA",
                component: () =>
                  import(
                    "@/views/finansial_keu/daftardetaillaporan/FinanceLaporanDetailMALA.vue"
                  ),
              },
            ],
          },
          {
            path: "/finansial-keu/pembayaranreguler",
            name: "Pembayaran Reguler Keu",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/finansial-keu/pembayaranreguler/datapembayaran",
            children: [
              {
                path: "/finansial-keu/pembayaranreguler/datapembayaran",
                name: "Data Pembayaran Keu",
                component: () =>
                  import(
                    "@/views/finansial_keu/pembayaranreguler/DataPembayaran.vue"
                  ),
              },
              {
                path: "/finansial-keu/detailpembayaran/:id",
                name: "Detail Pembayaran",
                component: () =>
                  import(
                    "@/views/finansial_keu/pembayaranreguler/DetailDataPembayaran.vue"
                  ),
              },
              {
                path: "/finansial-keu/detailpembayaranmala/:id",
                name: "Detail Pembayaran Mala",
                component: () =>
                  import(
                    "@/views/finansial_keu/pembayaranreguler/DetailDataPembayaranMala.vue"
                  ),
              },
              {
                path: "/finansial-keu/pembayaranreguler/beasiswa",
                name: "Beasiswa",
                component: () =>
                  import(
                    "@/views/finansial_keu/pembayaranreguler/Beasiswa.vue"
                  ),
              },
              {
                path: "/finansial-keu/pembayaranreguler/statusmahasiswa",
                name: "Status Mahasiswa",
                component: () =>
                  import(
                    "@/views/finansial_keu/pembayaranreguler/StatusMahasiswa.vue"
                  ),
              },
            ],
          },
          {
            path: "/finansial-keu/datatransaksibank",
            name: "Data Transaksi Bank Keu",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/finansial-keu/datatransaksibank",
            children: [
              {
                path: "/finansial-keu/datatransaksibank/konfirmasitransaksipembayaran",
                name: "Konfirmasi Transaksi Pembayaran Keuangan",
                component: () =>
                  import(
                    "@/views/finansial_keu/datatransaksibank/KonfirmasiTransaksiPembayaran.vue"
                  ),
              },
              {
                path: "/finansial-keu/datatransaksibank/laporantransaksirekon",
                name: "Laporan Transaksi Rekon",
                component: () =>
                  import(
                    "@/views/finansial_keu/datatransaksibank/LaporanTransaksiRekon.vue"
                  ),
              },
              {
                path: "/finansial-keu/datatransaksibank/rekeningkoran",
                name: "Transaksi Rekening Koran",
                component: () =>
                  import(
                    "@/views/finansial_keu/datatransaksibank/TransaksiRekeningKoran.vue"
                  ),
              },
            ],
          },
          {
            path: "/finansial-keu/pengaturan",
            name: "Pengaturan Finansial",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            children: [
              {
                path: "/finansial-keu/pengaturan/masterkomponenpembayaran",
                name: "Komponen Master Pembayaran",
                component: () =>
                  import("@/views/finansial_keu/pengaturan/MasterKomponen.vue"),
              },
              {
                path: "/finansial-keu/pengaturan/pengaturankomponen",
                name: "Pengaturan Komponen",
                component: () =>
                  import(
                    "@/views/finansial_keu/pengaturan/PengaturanKomponen.vue"
                  ),
              },
              {
                path: "/finansial-keu/pengaturan/komponenpembayaran/:id",
                name: "Komponen Pembayaran",
                component: () =>
                  import(
                    "@/views/finansial_keu/pengaturan/KomponenPembayaran.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "/pmb-adm",
        name: "PMB Admission",
        component: LayoutCard,
        redirect: "/pmb-adm/beranda",
        children: [
          {
            path: "/pmb-adm/importpmb",
            name: "Import Participant PMB",
            component: () => import("@/views/pmb_adm/ImportParticipantPMB.vue"),
          },
          {
            path: "/pmb-adm/beranda",
            name: "Beranda PMB ADMISSION",
            component: () => import("@/views/pmb_adm/Beranda.vue"),
          },
          {
            path: "/pmb-adm/alursistem",
            name: "Alur Sistem",
            component: () => import("@/views/pmb_adm/AlurSistem.vue"),
          },
          {
            path: "/pmb-adm/laporan",
            name: "Laporan PMB",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/pmb-adm/laporan/kontakpeserta",
            children: [
              {
                path: "/pmb-adm/laporan/kontakpeserta",
                name: "Laporan Kontak Peserta",
                component: () =>
                  import("@/views/pmb_adm/laporan/LaporanKontakPeserta.vue"),
              },
              {
                path: "/pmb-adm/laporan/pembayaran",
                name: "Laporan Pembayaran ADM",
                component: () =>
                  import("@/views/pmb_adm/laporan/LaporanPembayaran.vue"),
              },
              {
                path: "/pmb-adm/laporan/CetakBa",
                name: "Laporan Pembayaran Cetak BA ADM",
                component: () => import("@/views/pmb_adm/laporan/CetakBa.vue"),
              },
              {
                path: "/pmb-adm/laporan/regispertanggal",
                name: "Laporan Regist Per Tanggal",
                component: () =>
                  import(
                    "@/views/pmb_adm/laporan/LaporanRegistrasiPerTanggal.vue"
                  ),
              },
              {
                path: "/pmb-adm/laporan/agama",
                name: "Laporan Distribusi Agama ADM",
                component: () =>
                  import("@/views/pmb_adm/laporan/LaporanDistribusiAgama.vue"),
              },
              {
                path: "/pmb-adm/laporan/gender",
                name: "Laporan Distribusi Jenis Kelamin ADM",
                component: () =>
                  import("@/views/pmb_adm/laporan/LaporanDistribusiJK.vue"),
              },
              {
                path: "/pmb-adm/laporan/ukuranbaju",
                name: "Laporan Ukuran Baju ADM",
                component: () =>
                  import("@/views/pmb_adm/laporan/LaporanUkuranBaju.vue"),
              },
            ],
          },
          {
            path: "/pmb-adm/registrasi",
            name: "Registrasi ADM",
            component: () => import("@/views/pmb_adm/Registrasi.vue"),
          },
          {
            path: "/pmb-adm/registrasi/check/:id",
            name: "Registrasi Check",
            component: () => import("@/views/pmb_adm/RegistrasiCheck.vue"),
          },
        ],
      },
      {
        path: "/pmb-keu",
        name: "PMB KEUANGAN",
        component: LayoutCard,
        redirect: "/pmb-keu",
        children: [
          {
            path: "/pmb-keu/beranda",
            name: "Beranda PMB KEUANGAN",
            component: () => import("@/views/pmb_keu/Beranda.vue"),
          },
          {
            path: "/pmb-keu/alursistem",
            name: "Alur Sistem KEUANGAN",
            component: () => import("@/views/pmb_keu/AlurSistem.vue"),
          },
          {
            path: "/pmb-keu/pengajuanundurdiripeserta",
            name: "Pengajuan Undur Diri Peserta KEUANGAN",
            component: () =>
              import("@/views/pmb_keu/PengajuanUndurDiriPeserta.vue"),
          },
          {
            path: "/pmb-keu/pembayaran",
            name: "Pembayaran Keuangan",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/pmb-keu/pembayaran",
            children: [
              {
                path: "/pmb-keu/pembayaran/pengembalian",
                name: "Pengembalian Pembayaran Keuangan",
                component: () =>
                  import("@/views/pmb_keu/pembayaran/Pengembalian.vue"),
              },
              {
                path: "/pmb-keu/pembayaran/input",
                name: "Input Pembayaran Keuangan",
                component: () => import("@/views/pmb_keu/pembayaran/Input.vue"),
              },
              {
                path: "/pmb-keu/pembayaran/konfirmasi",
                name: "Konfirmasi Pembayaran Keuangan",
                component: () =>
                  import("@/views/pmb_keu/pembayaran/Konfirmasi.vue"),
              },
              {
                path: "/pmb-keu/pembayaran/beasiswa",
                name: "Beasiswa Keuangan",
                component: () =>
                  import("@/views/pmb_keu/pembayaran/Beasiswa.vue"),
              },
            ],
          },
          {
            path: "/pmb-keu/laporan",
            name: "Laporan Keuangan",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/pmb-keu/laporan",
            children: [
              {
                path: "/pmb-keu/laporan/laporanpembayaran",
                name: "Laporan Pembayaran Keuangan",
                component: () =>
                  import("@/views/pmb_keu/laporan/LaporanPembayaran.vue"),
              },
              {
                path: "/pmb-keu/laporan/laporandistribusiagama",
                name: "Laporan Distribusi Agama Keuangan",
                component: () =>
                  import("@/views/pmb_keu/laporan/LaporanDistribusiAgama.vue"),
              },
              {
                path: "/pmb-keu/laporan/laporandistribusijk",
                name: "Laporan Distribusi Jenis Kelamin Keuangan",
                component: () =>
                  import("@/views/pmb_keu/laporan/LaporanDistribusiJK.vue"),
              },
              {
                path: "/pmb-keu/laporan/laporanpesertapmb",
                name: "Laporan Peserta PMB Keuangan",
                component: () =>
                  import("@/views/pmb_keu/laporan/PesertaPmb.vue"),
              },
              {
                path: "/pmb-keu/laporan/laporanpembayarancetakba",
                name: "Laporan Pembayaran Cetak BA Keuangan",
                component: () => import("@/views/pmb_keu/laporan/CetakBa.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/presensi-dosen",
        name: "Presensi Dosen",
        component: LayoutCard,
        redirect: "/presensi-dosen",
        children: [
          {
            path: "/presensi-dosen/kehadiranmhs",
            name: "Kehadiran Mahasiswa",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/presensi-dosen/kehadiranmhs/per-perkuliahan",
            children: [
              {
                path: "/presensi-dosen/kehadiranmhs/per-perkuliahan",
                name: "Kehadiran per Perkuliahan",
                component: () =>
                  import("@/views/Dosen/presensi/KehadiranperPerkuliahan.vue"),
              },
            ],
          },
          {
            path: "/presensi-dosen/bap",
            name: "Berita Acara Perkuliahan",
            component: () => import("@/views/Dosen/presensi/BAP.vue"),
          },
          {
            path: "/presensi-dosen/submit-bap/:id",
            name: "Submit BAP",
            component: () => import("@/views/Dosen/presensi/SubmitBAP.vue"),
          },
          {
            path: "/presensi-dosen/detail-bap/:id",
            name: "Detail BAP",
            component: () => import("@/views/Dosen/presensi/DetailBAP.vue"),
          },
          {
            path: "/presensi-dosen/cetakdaftarhadir",
            name: "Cetak Daftar Hadir",
            component: () =>
              import("@/views/Dosen/presensi/CetakDaftarHadir.vue"),
          },
          {
            path: "/presensi-dosen/evaluasipembelajaran",
            name: "Evaluasi Pembelajaran",
            component: () =>
              import("@/views/Dosen/presensi/EvaluasiPembelajaran.vue"),
          },
          {
            path: "/presensi-dosen/honor",
            name: "Honor Kehadiran",
            component: () =>
              import("@/views/Dosen/presensi/HonorKehadiran.vue"),
          },
          {
            path: "/presensi-dosen/jadwaldosen",
            name: "Jadwal Dosen Presensi",
            component: () => import("@/views/Dosen/presensi/JadwalDosen.vue"),
          },
          {
            path: "/presensi-dosen/daftarmahasiswamk/:id",
            name: "Daftar Mahasiswa Mata Kuliah",
            component: () =>
              import("@/views/Dosen/presensi/DaftarMahasiswaMK.vue"),
          },
        ],
      },
      {
        path: "/kurikulum-kaprodi",
        name: "Kurikulum Kaprodi",
        component: LayoutCard,
        redirect: "/kurikulum-kaprodi/beranda",
        children: [
          {
            path: "/kurikulum-kaprodi/beranda",
            name: "Beranda Kurikulum Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/Beranda.vue"),
          },
          {
            path: "/kurikulum-kaprodi/kelolakurikulum",
            name: "Kelola Kurikulum",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/kurikulum-kaprodi/kelolakurikulum/lihatkurikulum",
            children: [
              {
                path: "/kurikulum-kaprodi/kelolakurikulum/lihatkurikulum",
                name: "Lihat Kurikulum",
                component: () =>
                  import("@/views/kurikulum_kaprodi/LihatKurikulum.vue"),
              },
              {
                path: "/kurikulum-kaprodi/kelolakurikulum/tambahmatkul",
                name: "Tambah Mata Kuliah",
                component: () =>
                  import("@/views/kurikulum_kaprodi/TambahMataKuliah.vue"),
              },
              {
                path: "/kurikulum-kaprodi/kelolakurikulum/peminatanprodi",
                name: "Kelola Peminatan Prodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/KelolaPeminatanProdi.vue"),
              },
              {
                path: "/kurikulum-kaprodi/kelolakurikulum/impormatkul",
                name: "Impor Mata Kuliah",
                component: () =>
                  import("@/views/kurikulum_kaprodi/ImporMatkul.vue"),
              },
              {
                path: "/kurikulum-kaprodi/kelolakurikulum/editmatakuliah/:code",
                name: "Edit Mata Kuliah",
                component: () =>
                  import("@/views/kurikulum_kaprodi/EditMataKuliah.vue"),
              },
            ],
          },
          {
            path: "/kurikulum-kaprodi/persetujuankurikulum",
            name: "Persetujuan Kurikulum",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect:
              "/kurikulum-kaprodi/persetujuankurikulum/riwayatkurikulum",
            children: [
              {
                path: "/kurikulum-kaprodi/persetujuankurikulum/riwayatkurikulum",
                name: "Riwayat Kurikulum",
                component: () =>
                  import("@/views/kurikulum_kaprodi/RiwayatKurikulum.vue"),
              },
              {
                path: "/kurikulum-kaprodi/persetujuankurikulum/pilihtipe/:id",
                name: "Pilih Tipe",
                component: () =>
                  import("@/views/kurikulum_kaprodi/riwayat/PilihTipe.vue"),
              },
              {
                path: "/kurikulum-kaprodi/persetujuankurikulum/editmatkul/:id",
                name: "Edit Mata Kuliah",
                component: () =>
                  import("@/views/kurikulum_kaprodi/riwayat/Edit.vue"),
              },
            ],
          },
          {
            path: "/kurikulum-kaprodi/silabus",
            name: "Silabus Kaprodi",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/kurikulum-kaprodi/silabus/aturrps",
            children: [
              {
                path: "/kurikulum-kaprodi/silabus/aturrps",
                name: "Atur RPS Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/AturRPS.vue"),
              },
              {
                path: "/kurikulum-kaprodi/silabus/aturrps/aturgbpp/:id",
                name: "Atur GBPP Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/AturGBPP.vue"),
              },
              {
                path: "/kurikulum-kaprodi/silabus/aturrps/atursap/:id",
                name: "Atur SAP Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/AturSAP.vue"),
              },
              {
                path: "/kurikulum-kaprodi/silabus/lihatrps",
                name: "Lihat RPS Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/LihatRPS.vue"),
              },
              {
                path: "/kurikulum-kaprodi/silabus/lihatrps/lihatgbpp/:id",
                name: "Lihat GBPP Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/LihatGBPP.vue"),
              },
              {
                path: "/kurikulum-kaprodi/silabus/lihatrps/lihatsap/:id",
                name: "Lihat SAP Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/LihatSAP.vue"),
              },
              {
                path: "/kurikulum-kaprodi/silabus/statusinputrps",
                name: "Status Input RPS Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/StatusInputRPS.vue"),
              },
              {
                path: "/kurikulum-kaprodi/silabus/ubahrps",
                name: "Ubah RPS Kaprodi",
                component: () =>
                  import("@/views/kurikulum_kaprodi/UbahRPS.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/registrasi-kaprodi",
        name: "Registrasi Kaprodi",
        component: LayoutCard,
        redirect: "/registrasi-kaprodi/beranda",
        children: [
          {
            path: "/registrasi-kaprodi/beranda",
            name: "Beranda Registrasi Kaprodi",
            component: () => import("@/views/Registrasi_Kaprodi/Beranda.vue"),
          },
          {
            path: "/registrasi-kaprodi/pilihmahasiswa",
            name: "Pilih Mahasiswa",
            component: () => import("@/views/Registrasi_Kaprodi/Mahasiswa.vue"),
          },
          {
            path: "/registrasi-kaprodi/regis",
            name: "Registrasi Kaprodi",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasi-kaprodi/regis/regismatkul",
            children: [
              {
                path: "/registrasi-kaprodi/regis/regismatkul",
                name: "Registrasi Mata Kuliah Kaprodi",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/Regis_Matkul.vue"),
              },
              {
                path: "/registrasi-kaprodi/regis/statusmahasiswa",
                name: "Status Registrasi Mahasiswa ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/Status.vue"),
              },
              {
                path: "/registrasi-kaprodi/regis/arsip",
                name: "Arsip KSM ",
                component: () => import("@/views/Registrasi_Kaprodi/Arsip.vue"),
              },
              {
                path: "/registrasi-kaprodi/regis/history",
                name: "History Proses Registrasi ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/History.vue"),
              },
            ],
          },
          {
            path: "/registrasi-kaprodi/matkul",
            name: "Mata Kuliah ",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasi-kaprodi/matkul/daftarmatkul",
            children: [
              {
                path: "/registrasi-kaprodi/matkul/daftarmatkul",
                name: "Daftar Mata Kuliah ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/DaftarMatKul.vue"),
              },
              {
                path: "/registrasi-kaprodi/matkul/tambahmatkul",
                name: "Tambah Mata Kuliah ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/TambahMatKul.vue"),
              },
              {
                path: "/registrasi-kaprodi/matkul/impormatkul",
                name: "Impor Mata Kuliah ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/ImporMatKul.vue"),
              },
              {
                path: "/registrasi-kaprodi/matkul/alokasimatkul",
                name: "Alokasi Mata Kuliah ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/AlokasiMatKul.vue"),
              },
              {
                path: "/registrasi-kaprodi/matkul/ubahmatkul/:id",
                name: "Ubah Mata Kuliah ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/UbahMatkul.vue"),
              },
              {
                path: "/registrasi-kaprodi/matkul/koordinatormatkul",
                name: "Koordinator Mata Kuliah ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/KoordinatorMatKul.vue"),
              },
              {
                path: "/registrasi-kaprodi/matkul/statusmatkul",
                name: "Status Mata Kuliah ",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/StatusMatKul.vue"),
              },
              {
                path: "/registrasi-kaprodi/matkul/tamatkul",
                name: "Mendaftarkan  Mata Kuliah TA/PA",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/TaMatKul.vue"),
              },
            ],
          },
          {
            path: "/registrasi-kaprodi/pengaturan",
            name: "Pengaturan Kaprodi",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasi-kaprodi/pengaturan/aturansks",
            children: [
              {
                path: "/registrasi-kaprodi/pengaturan/aturansks",
                name: "Aturan Maks SKS",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/AturanMaksSKS.vue"),
              },
            ],
          },
          {
            path: "/registrasi-kaprodi/master",
            name: "Master",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/registrasi-kaprodi/master/ruangan",
            children: [
              {
                path: "/registrasi-kaprodi/master/ruangan",
                name: "Ruangan",
                component: () =>
                  import("@/views/Registrasi_Kaprodi/Ruangan.vue"),
              },
              {
                path: "/registrasi-kaprodi/master/shift",
                name: "Shift",
                component: () => import("@/views/Registrasi_Kaprodi/Shift.vue"),
              },
              {
                path: "/registrasi-kaprodi/master/waktu",
                name: "Waktu",
                component: () => import("@/views/Registrasi_Kaprodi/Waktu.vue"),
              },
            ],
          },
          {
            path: "/jadwal-kaprodi",
            name: "Jadwal Kaprodi",

            redirect: "/jadwal-kaprodi/tambahjadwal",
            children: [
              {
                path: "/jadwal-kaprodi/tambahjadwal",
                name: "Tambah Jadwal",
                component: () =>
                  import("@/views/Jadwal_Kaprodi/TambahJadwal.vue"),
              },
              {
                path: "/jadwal-kaprodi/lihatjadwal",
                name: "lihat Jadwal",
                component: () =>
                  import("@/views/Jadwal_Kaprodi/LihatJadwal.vue"),
              },
              {
                path: "/jadwal-kaprodi/daftarmahasiswamatkul/:id",
                name: "Daftar Mahasiswa Mata Kuliah",
                component: () =>
                  import("@/views/Jadwal_Kaprodi/DaftarMahasiswaMatkul.vue"),
              },
              {
                path: "/jadwal-kaprodi/editjadwal/:id",
                name: "Edit Jadwal",
                component: () =>
                  import("@/views/Jadwal_Kaprodi/EditJadwal.vue"),
              },
              {
                path: "/jadwal-kaprodi/ruangkosong",
                name: "Ruang Kosong",
                component: () =>
                  import("@/views/Jadwal_Kaprodi/RuangKosong.vue"),
              },
              {
                path: "/jadwal-kaprodi/importjadwal",
                name: "Import Jadwal",
                component: () =>
                  import("@/views/Jadwal_Kaprodi/ImportJadwal.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/perwalian-kaprodi",
        name: "Perwalian Kaprodi",
        component: LayoutCard,
        redirect: "/perwalian-kaprodi/beranda",
        children: [
          {
            path: "/perwalian-kaprodi/beranda",
            name: "Beranda Kurikulum Kaprodi",
            component: () => import("@/views/perwalian_kaprodi/Beranda.vue"),
          },
          {
            path: "/perwalian-kaprodi/mahasiswa",
            name: "Mahasiswa",
            component: () => import("@/views/perwalian_kaprodi/Mahasiswa.vue"),
          },
          {
            path: "/perwalian-kaprodi/catatanlks",
            name: "Catatan LKS",
            component: () => import("@/views/perwalian_kaprodi/CatatanLKS.vue"),
          },
          {
            path: "/perwalian-kaprodi/kemajuanstudi/:nim",
            name: "Kemajuan Studi",
            component: () =>
              import("@/views/perwalian_kaprodi/KemajuanStudi.vue"),
          },
        ],
      },
      {
        path: "/feeder",
        name: "Feeder",
        component: LayoutCard,
        redirect: "/feeder/aktivitasmahasiswa",
        children: [
          {
            path: "/feeder/aktivitasmahasiswa",
            name: "AktivitasMahasiswa",
            component: () => import("@/views/Feeder/AktivitasMahasiswa.vue"),
          },
          // {
          //   path: "/feeder/mahasiswa",
          //   name: "Mahasiswa",
          //   component: {
          //     render() {
          //       return h(resolveComponent("router-view"));
          //     },
          //   },
          //   redirect: "/feeder/mahasiswa/aktivitaskuliah",
          //   children: [
          //     {
          //       path: "/feeder/mahasiswa/aktivitaskuliah",
          //       name: "Aktivitas Perkuliahan",
          //       component: () => import("@/views/Feeder/AktivitasKuliah.vue"),
          //     },
          //   ],
          // },
        ],
      },
      {
        path: "/datamahasiswa",
        name: "Data Mahasiwa",
        component: LayoutCard,
        redirect: "/datamahasiswa/daftardosenwali",
        children: [
          {
            path: "/datamahasiswa/daftardosenwali",
            name: "Daftar Dosen Wali",
            component: () =>
              import("@/views/DataMahasiswa_BAA/DaftarDosenWali.vue"),
          },
          {
            path: "/datamahasiswa/daftarmahasiswawali/:id",
            name: "Daftar Mahasiswa Wali",
            component: () =>
              import("@/views/DataMahasiswa_BAA/DaftarMahasiswaWali.vue"),
          },
          {
            path: "/datamahasiswa/statusregistrasi",
            name: "Status Registrasi BAA",
            component: () =>
              import("@/views/DataMahasiswa_BAA/StatusRegistrasi.vue"),
          },
          {
            path: "/datamahasiswa/statustahap/:id",
            name: "Status Tahap",
            component: () =>
              import("@/views/DataMahasiswa_BAA/StatusTahap.vue"),
          },
          {
            path: "/datamahasiswa/mahasiswa_baa",
            name: "Mahasiswa BAA",
            component: () => import("@/views/DataMahasiswa_BAA/Mahasiswa.vue"),
          },
          {
            path: "/datamahasiswa/profilmahasiswa_baa/:id",
            name: "Profil Mahasiswa",
            component: () =>
              import("@/views/DataMahasiswa_BAA/ProfilMahasiswa.vue"),
          },
          {
            path: "/datamahasiswa/laporanstudi",
            name: "Laporan Studi",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/feeder/mahasiswa/datamahasiswa",
            children: [
              {
                path: "/feeder/mahasiswa/datamahasiswa",
                name: "Data Mahasiswa",
                component: () => import("@/views/Feeder/DataMahasiswa.vue"),
              },
              {
                path: "/feeder/mahasiswa/aktivitasperkuliahan",
                name: "Aktivitas Perkuliahan",
                component: () =>
                  import("@/views/Feeder/AktivitasPerkuliahan.vue"),
              },
            ],
          },
          {
            path: "/feeder/dosen",
            name: "Dosen",
            component: () => import("@/views/Feeder/Dosen.vue"),
          },
          {
            path: "/feeder/matkuldankurikulum",
            name: "Mata Kuliah & Kurikulum",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/feeder/matkuldankurikulum/kurikulum",
            children: [
              {
                path: "/feeder/matkuldankurikulum/kurikulum",
                name: "Feeder Kurikulum",
                component: () => import("@/views/Feeder/Kurikulum.vue"),
              },
              {
                path: "/feeder/matkuldankurikulum/matakuliah",
                name: "Mata Kuliah",
                component: () => import("@/views/Feeder/MataKuliah.vue"),
              },
              {
                path: "/feeder/matkuldankurikulum/matakuliahkurikulum",
                name: "Mata Kuliah Kurikulum",
                component: () =>
                  import("@/views/Feeder/MataKuliahKurikulum.vue"),
              },
            ],
          },
          {
            path: "/feeder/kelasperkuliahan",
            name: "Kelas Perkuliahan",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/feeder/kelasperkuliahan/kelaskuliah",
            children: [
              {
                path: "/feeder/kelasperkuliahan/kelaskuliah",
                name: "Kelas Kuliah",
                component: () => import("@/views/Feeder/KelasKuliah.vue"),
              },
              {
                path: "/feeder/kelasperkuliahan/dosenpengajar",
                name: "Dosen Pengajar",
                component: () => import("@/views/Feeder/DosenPengajar.vue"),
              },
              {
                path: "/feeder/kelasperkuliahan/pesertakelas",
                name: "Peserta Kelas",
                component: () => import("@/views/Feeder/PesertaKelas.vue"),
              },
            ],
          },
          {
            path: "/feeder/nilaiperkuliahan",
            name: "Nilai Perkuliahan",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/feeder/nilaiperkuliahan/bobotnilai",
            children: [
              {
                path: "/feeder/nilaiperkuliahan/bobotnilai",
                name: "Skala/Bobot Nilai",
                component: () => import("@/views/Feeder/BobotNilai.vue"),
              },
              {
                path: "/feeder/nilaiperkuliahan/nilaimatkul",
                name: "Nilai Matakuliah",
                component: () => import("@/views/Feeder/NilaiMatkul.vue"),
              },
              {
                path: "/feeder/nilaiperkuliahan/konversi",
                name: "Nilai Transfer / Konversi",
                component: () => import("@/views/Feeder/Konversi.vue"),
                redirect: "/datamahasiswa/laporanstudi/laporanipk",
                children: [
                  {
                    path: "/datamahasiswa/laporanstudi/laporanipk",
                    name: "Laporan IPK Per Tahun Lulus",
                    component: () =>
                      import("@/views/DataMahasiswa_BAA/IpkPerTahun.vue"),
                  },
                  {
                    path: "/datamahasiswa/laporanstudi/laporanipksemester",
                    name: "Laporan IPK Per Semster",
                    component: () =>
                      import("@/views/DataMahasiswa_BAA/IpkPerSemester.vue"),
                  },
                  {
                    path: "/datamahasiswa/laporanstudi/lamastudi",
                    name: "Lama Studi",
                    component: () =>
                      import("@/views/DataMahasiswa_BAA/LamaStudi.vue"),
                  },
                  {
                    path: "/datamahasiswa/laporanstudi/detailipk",
                    name: "Detail Laporan IPK Per Semester",
                    component: () =>
                      import("@/views/DataMahasiswa_BAA/DetailLaporanIpk.vue"),
                  },
                  {
                    path: "/datamahasiswa/laporanstudi/detaillamastudi",
                    name: "Detail Lama Studi",
                    component: () =>
                      import("@/views/DataMahasiswa_BAA/DetailLamaStudi.vue"),
                  },
                  {
                    path: "/datamahasiswa/laporanstudi/detailpertahun",
                    name: "Detail Laporan IPK Per Tahun Lulus",
                    component: () =>
                      import("@/views/DataMahasiswa_BAA/DetailPerTahun.vue"),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/pmb-camaba",
        name: "PMB CAMABA",
        component: LayoutCard,
        redirect: "/pmb-camaba/beranda",
        children: [
          {
            path: "/pmb-camaba/beranda",
            name: "Beranda Camaba",
            component: () => import("@/views/Camaba/Beranda.vue"),
          },
          {
            path: "/pmb-camaba/alursistem",
            name: "Alur Sistem Camaba",
            component: () => import("@/views/Camaba/AlurSistem.vue"),
          },
          {
            path: "/pmb-camaba/statuspembayaran",
            name: "Status Pembayaran",
            component: () => import("@/views/Camaba/StatusPembayaran.vue"),
          },
          {
            path: "/pmb-camaba/mengisidatapeserta",
            name: "Mengisi Data Peserta",
            component: () => import("@/views/Camaba/MengisiDataPeserta.vue"),
          },
          {
            path: "/pmb-camaba/dokumenpeserta",
            name: "Dokumen Peserta PMB",
            component: () => import("@/views/Camaba/DokumenPesertaPMB.vue"),
          },
          {
            path: "/pmb-camaba/undurdiri",
            name: "Pengajuan Undur Diri Peserta",
            component: () => import("@/views/Camaba/PengajuanUndurDiri.vue"),
          },
          {
            path: "/pmb-camaba/undurdiri/:id",
            name: "Undur Diri Peserta",
            component: () => import("@/views/Camaba/UndurDiri.vue"),
          },
        ],
      },
      {
        path: "/presensi_baa",
        name: "Presensi BAA",
        component: LayoutCard,
        redirect: "/presensi_baa/cetakdaftarhadir",
        children: [
          {
            path: "/presensi_baa/cetakdaftarhadir",
            name: "Cetak Daftar Hadir BAA",
            component: () => import("@/views/Presensi_BAA/CetakDaftar.vue"),
          },
          {
            path: "/presensi_baa/beritaacaraperkuliahan",
            name: "Berita Acara Perkuliahan BAA",
            component: () => import("@/views/Presensi_BAA/BeritaAcara.vue"),
          },
          {
            path: "/presensi_baa/submitberita/:id",
            name: "Submit Berita ",
            component: () => import("@/views/Presensi_BAA/SubmitBerita.vue"),
          },
          {
            path: "/presensi_baa/kehadiranmahasiswa",
            name: "Kehadiran Mahasiswa ",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/presensi_baa/kehadiranmahasiswa/lihatmahasiswa",
            children: [
              {
                path: "/presensi_baa/kehadiranmahasiswa/lihatmahasiswa",
                name: "Lihat Mahasiwa",
                component: () =>
                  import("@/views/Presensi_BAA/LihatMahasiswa.vue"),
              },
            ],
          },
          {
            path: "/presensi_baa/bantuanjadwal",
            name: "Bantuan Jadwal ",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/presensi_baa/bantuanjadwal/jadwaldosen",
            children: [
              {
                path: "/presensi_baa/bantuanjadwal/jadwaldosen",
                name: "Jadwal Dosen",
                component: () => import("@/views/Presensi_BAA/JadwalDosen.vue"),
              },
            ],
          },
          {
            path: "/presensi_baa/Perkuliahan",
            name: "Perkuliahan ",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/presensi_baa/Perkuliahan/lihatperkuliahan",
            children: [
              {
                path: "/presensi_baa/Perkuliahan/lihatperkuliahan",
                name: "Lihat Perkuliahan",
                component: () =>
                  import("@/views/Presensi_BAA/LihatPerkuliahan.vue"),
              },
            ],
          },
          {
            path: "/presensi_baa/nilai",
            name: "Nilai ",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/presensi_baa/nilai/jadwalinputnilai",
            children: [
              {
                path: "/presensi_baa/nilai/jadwalinputnilai",
                name: "Jadwal Input Nilai",
                component: () =>
                  import("@/views/Nilai_BAA/JadwalInputNilai.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/tak_bk",
        name: "TAK BK",
        component: LayoutCard,
        redirect: "/tak_bk/daftartak",
        children: [
          {
            path: "/tak_bk/daftartak",
            name: "Daftar Tak Mahasiswa",
            component: () => import("@/views/tak_bk/DaftarTAK.vue"),
          },
          {
            path: "/tak_bk/pengaturanpoin",
            name: "Pengaturan Poin",
            component: () => import("@/views/tak_bk/PengaturanPoin.vue"),
          },
          {
            path: "/tak_bk/cetaktak",
            name: "Cetak TAK BK",
            component: () => import("@/views/tak_bk/CetakTAK.vue"),
          },
          {
            path: "/tak_bk/cetakskpi",
            name: "Cetak SKPI",
            component: () => import("@/views/tak_bk/CetakSKPI.vue"),
          },
        ],
      },

      {
        path: "/survey_admin",
        name: "Survey Admin",
        component: LayoutCard,
        redirect: "/survey_admin/kuesioner",
        children: [
          {
            path: "/survey_admin/kuesioner",
            name: "Kuesioner",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/survey_admin/kuesioner/datakuesioner",
            children: [
              {
                path: "/survey_admin/kuesioner/datakuesioner",
                name: "Data Kuesioner",
                component: () =>
                  import("@/views/survey_admin/DataKuesioner.vue"),
              },
              {
                path: "/survey_admin/kuesioner/detailkuesioner/:id",
                name: "Detail Kuesioner",
                component: () =>
                  import("@/views/survey_admin/DetailKuesioner.vue"),
              },
              {
                path: "/survey_admin/kuesioner/laporan/:id",
                name: "Laporan Kuesioner",
                component: () =>
                  import("@/views/survey_admin/LaporanKuesioner.vue"),
              },
            ],
          },
          {
            path: "/survey_admin/kepuasanpegawai",
            name: "Hasil Kuesioner Pegawai",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/survey_admin/kepuasanpegawai/universitas",
            children: [
              {
                path: "/survey_admin/kepuasanpegawai/universitas",
                name: "Kepuasan Universitas",
                component: () =>
                  import("@/views/survey_admin/KepuasanUniversitas.vue"),
              },
              {
                path: "/survey_admin/kepuasanpegawai/perwarek",
                name: "Kepuasan per Warek",
                component: () =>
                  import("@/views/survey_admin/KepuasanPerWarek.vue"),
              },
              {
                path: "/survey_admin/kepuasanpegawai/perfakultas",
                name: "Kepuasan per Fakultas",
                component: () =>
                  import("@/views/survey_admin/KepuasanPerWarek.vue"),
              },
              {
                path: "/survey_admin/kepuasanpegawai/perprodi",
                name: "Kepuasan per Program Studi",
                component: () =>
                  import("@/views/survey_admin/KepuasanPerProdi.vue"),
              },
              {
                path: "/survey_admin/kepuasanpegawai/perdirektorat",
                name: "Kepuasan per Direktorat",
                component: () =>
                  import("@/views/survey_admin/KepuasanPerDirektorat.vue"),
              },
              {
                path: "/survey_admin/kepuasanpegawai/perunit",
                name: "Kepuasan per Unit",
                component: () =>
                  import("@/views/survey_admin/KepuasanPerUnit.vue"),
              },
            ],
          },
          {
            path: "/survey_admin/kuesioneredom",
            name: "Hasil Kuesioner EDOM",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/survey_admin/kuesioneredom/laporandosen",
            children: [
              {
                path: "/survey_admin/kuesioneredom/laporandosen",
                name: "Laporan Dosen",
                component: () =>
                  import("@/views/survey_admin/LaporanDosen.vue"),
              },
              {
                path: "/survey_admin/kuesioneredom/laporanmatkul",
                name: "Laporan Mata Kuliah",
                component: () =>
                  import("@/views/survey_admin/LaporanMatkul.vue"),
              },
              {
                path: "/survey_admin/kuesioneredom/laporanprodi",
                name: "Laporan Program Studi",
                component: () =>
                  import("@/views/survey_admin/LaporanProdi.vue"),
              },
              {
                path: "/survey_admin/kuesioneredom/laporanmatkulperfakultas",
                name: "Laporan Matakuliah Per Fakultas",
                component: () =>
                  import("@/views/survey_admin/LaporanMatkulPerFakultas.vue"),
              },
              {
                path: "/survey_admin/kuesioneredom/laporanperdosen",
                name: "Detail Laporan per Dosen",
                component: () =>
                  import("@/views/survey_admin/DetailLaporanPerDosen.vue"),
              },
              {
                path: "/survey_admin/kuesioneredom/laporanpertanyaanterbuka",
                name: "Laporan Pertanyaan Terbuka",
                component: () =>
                  import("@/views/survey_admin/LaporanPertanyaanTerbuka.vue"),
              },
              {
                path: "/survey_admin/kuesioneredom/grafikdosen",
                name: "Grafik Dosen Per Program Studi",
                component: () => import("@/views/survey_admin/GrafikDosen.vue"),
              },
            ],
          },
        ],
      },

      {
        path: "/admin-ppm",
        name: "Admin PPM",
        component: LayoutCard,
        redirect: "/admin-ppm/pengaturan",
        children: [
          {
            path: "/admin-ppm/pengaturan",
            name: "Pengaturan",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/admin-ppm/pengaturan/eventkegiatan",
            children: [
              {
                path: "/admin-ppm/pengaturan/eventkegiatan",
                name: "Pengaturan Event Kegiatan",
                component: () =>
                  import("@/views/Admin_PPM/PengaturanEventKegiatan.vue"),
              },
            ],
          },
          {
            path: "/admin-ppm/pengajuanjurnal",
            name: "Pengajuan Jurnal",
            component: () => import("@/views/Admin_PPM/PengajuanJurnal.vue"),
          },
        ],
      },

      {
        path: "/struktur",
        name: "Struktur Organisasi",
        component: LayoutCard,
        redirect: "/struktur/lihatstruktur",
        children: [
          {
            path: "/struktur/lihatstruktur",
            name: "Lihat Struktur Organisasi",
            component: () =>
              import("@/views/Admin_SDM/LihatStrukturOrganisasi.vue"),
          },
          {
            path: "/struktur/posisistruktur",
            name: "Posisi Struktur Organisasi",
            component: () => import("@/views/Admin_SDM/PosisiStruktur.vue"),
          },
        ],
      },
      {
        path: "/admin-ppm",
        name: "Admin PPM",
        component: LayoutCard,
        redirect: "/admin-ppm/acara/buatpengumuman",
        children: [
          {
            path: "/admin-ppm/acara",
            name: "Acara ",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/admin-ppm/acara/buatpengumuman",
            children: [
              {
                path: "/admin-ppm/acara/buatpengumuman",
                name: "Buat Pengumuman",
                component: () =>
                  import("@/views/Admin_PPM/acara/BuatPengumuman.vue"),
              },
            ],
          },
          {
            path: "/admin-ppm/laporanpenelitian",
            name: "Laporan Penelitian ",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/admin-ppm/laporanpenelitian/lihatsemuajurnal",
            children: [
              {
                path: "/admin-ppm/laporanpenelitian/lihatsemuajurnal",
                name: "Lihat Semua Jurnal",
                component: () =>
                  import(
                    "@/views/Admin_PPM/laporanpenelitian/LihatSemuaJurnal.vue"
                  ),
              },
              {
                path: "/admin-ppm/laporanpenelitian/laporanpenelitiandosen",
                name: "Laporan Penelitian Dosen",
                component: () =>
                  import(
                    "@/views/Admin_PPM/laporanpenelitian/LaporanPenelitianDosen.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "/kelolask",
        name: "Kelola SK",
        component: LayoutCard,
        redirect: "/kelolask/terbitkansk",
        children: [
          {
            path: "/kelolask/terbitkansk",
            name: "Terbitkan SK",
            component: () => import("@/views/Admin_LAAK/TerbitkanSk.vue"),
          },
        ],
      },
      {
        path: "/daftartapa",
        name: "Daftar TA/PA",
        component: LayoutCard,
        redirect: "/daftartapa/statustapa",
        children: [
          {
            path: "/daftartapa/statustapa",
            name: "Status TA/PA Mahasiswa",
            component: () => import("@/views/Admin_LAAK/StatusTAPA.vue"),
          },
          {
            path: "/daftartapa/cetakpra/:id",
            name: "Cetak Kelengkapan Pra Sidang ",
            component: () => import("@/views/Admin_LAAK/Cetak.vue"),
          },
          {
            path: "/daftartapa/regislaak",
            name: "Registrasi Mata Kuliah LAAK",
            component: () => import("@/views/Admin_LAAK/RegisLAAK.vue"),
          },
          {
            path: "/daftartapa/unggahmahasiswa",
            name: "Unggah Mahasiswa Siap Sidang ",
            component: () => import("@/views/Admin_LAAK/UnggahMahasiswa.vue"),
          },
          {
            path: "/daftartapa/rekapmahasiswa",
            name: "Rekap TA/PA Mahasiswa  ",
            component: () => import("@/views/Admin_LAAK/RekapTAPA.vue"),
          },
          {
            path: "/daftartapa/mahasiswayanglulus",
            name: "Mahasiswa yang lulus Sidang Akademik untuk Siap Sidang ",
            component: () => import("@/views/Admin_LAAK/MahasiswaLulus.vue"),
          },
          {
            path: "/daftartapa/lihatdosen",
            name: "Lihat Dosen Pembimbing di Kelompok Keahlian",
            component: () => import("@/views/Admin_LAAK/LihatDosen.vue"),
          },
          {
            path: "/daftartapa/finaltask",
            name: "Finaltask KK Advisor Detail",
            component: () => import("@/views/Admin_LAAK/Finaltask.vue"),
          },
        ],
      },
      {
        path: "/sidang",
        name: "Sidang",
        component: LayoutCard,
        redirect: "/sidang/finaltask",
        children: [
          {
            path: "/sidang/finaltask",
            name: "Final Task Checklist Sidang",
            component: () => import("@/views/Admin_LAAK/Checklist.vue"),
          },
        ],
      },

      //beasiswa
      {
        path: "/beasiswa",
        name: "Beasiswa",
        component: LayoutCard,
        redirect: "/beasiswa/lihatjenisbeasiswa",
        children: [
          {
            path: "/beasiswa/lihatjenisbeasiswa",
            name: "Lihat Jenis Beasiswa",
            component: () => import("@/views/beasiswa/LihatJenisBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa",
            name: "Beasiswa Mahasiswa",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
            children: [
              {
                path: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
                name: "Lihat Jenis Beasiswa Mahasiswa",
                component: () =>
                  import("@/views/beasiswa/LihatJenisBeasiswaMahasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamahasiswa/editaturan/:id",
                name: "Edit Aturan Beasiswa",
                component: () =>
                  import("@/views/beasiswa/EditAturanBeasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamahasiswa/tambahbeasiswa",
                name: "Tambah Beasiswa",
                component: () => import("@/views/beasiswa/TambahBeasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamahasiswa/penerimabeasiswa",
                name: "Penerima Beasiswa",
                component: () =>
                  import("@/views/beasiswa/PenerimaBeasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamahasiswa/penerimabeasiswamhs",
                name: "Lihat Penerima Beasiswa Mahasiswa",
                component: () =>
                  import("@/views/beasiswa/LihatPenerimaBeasiswaMahasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamahasiswa/importpenerima",
                name: "Import Penerima Beasiswa",
                component: () =>
                  import("@/views/beasiswa/ImportPenerimaBeasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamahasiswa/aprovalbeasiswa",
                name: "Approval Penerima Beasiswa",
                component: () =>
                  import("@/views/beasiswa/AprovalPenerimaBeasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamahasiswa/aprovalstatusbeasiswa",
                name: "Approval Status Penerima Beasiswa",
                component: () =>
                  import("@/views/beasiswa/AprovalStatusPenerimaBeasiswa.vue"),
              },
            ],
          },
          {
            path: "/beasiswa/beasiswamaba",
            name: "Potongan Mahasiswa Baru ",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/beasiswa/beasiswamaba/beasiswacalonmahasiswa",
            children: [
              {
                path: "/beasiswa/beasiswamaba/jenispotongan",
                name: "Potongan Calon Mahasiswa",
                component: () =>
                  import("@/views/beasiswa/BeasiswaCalonMahasiswa.vue"),
              },
              {
                path: "/beasiswa/beasiswamaba/lihatjenisbeasiswamaba",
                name: "Lihat Jenis Potongan Maba",
                component: () =>
                  import("@/views/beasiswa/LihatJenisBeasiswaMaba.vue"),
              },
              {
                path: "/beasiswa/beasiswamaba/lihatpenerimabeasiswa",
                name: "Lihat Penerima Potongan",
                component: () =>
                  import("@/views/beasiswa/LihatPenerimaBeasiswa.vue"),
              },
            ],
          },
          {
            path: "/beasiswa/laporanbeasiswa",
            name: "Laporan Beasiswa ",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              },
            },
            redirect: "/beasiswa/laporanbeasiswa/laporanbeasiswamaba",
            children: [
              {
                path: "/beasiswa/laporanbeasiswa/laporanbeasiswapersemester",
                name: "Laporan Beasiswa Per Semester",
                component: () =>
                  import("@/views/beasiswa/LaporanBeasiswaPerSemester.vue"),
              },
              {
                path: "/beasiswa/laporanbeasiswa/laporanpenerimabeasiswa",
                name: "Laporan Penerima Beasiswa",
                component: () =>
                  import("@/views/beasiswa/LaporanPenerimaBeasiswa.vue"),
              },
              {
                path: "/beasiswa/laporanbeasiswa/laporanbeasiswaperprodi",
                name: "Laporan Per Prodi",
                component: () =>
                  import("@/views/beasiswa/LaporanBeasiswaPerProdi.vue"),
              },
              {
                path: "/beasiswa/laporanbeasiswa/laporanpotongan",
                name: "Laporan Potongan",
                component: () => import("@/views/beasiswa/LaporanPotongan.vue"),
              },
            ],
          },
        ],
      },

      {
        path: "/pages",
        redirect: "/pages/404",
        name: "Pages",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        children: [
          {
            path: "404",
            name: "Page404",
            component: () => import("@/views/pages/Page404"),
          },
          {
            path: "500",
            name: "Page500",
            component: () => import("@/views/pages/Page500"),
          },
          {
            path: "admission",
            name: "Admission",
            component: () => import("@/views/pages/Admission"),
          },
          {
            path: "dela",
            name: "Dela",
            component: () => import("@/views/pages/Dela"),
          },
          {
            path: "login",
            name: "Login",
            component: () => import("@/views/pages/Login"),
          },
          {
            path: "parentslogin",
            name: "ParentsLogin",
            component: () => import("@/views/pages/ParentsLogin"),
          },
          {
            path: "register",
            name: "Register",
            component: () => import("@/views/pages/Register"),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
