export default [
  {
    component: "CNavGroup",
    name: "Akun Dosen",
    to: "/akun_dosen",
    items: [
      {
        component: "CNavGroup",
        name: "ProfilKu",
        to: "/profilku",
        items: [
          {
            component: "CNavItem",
            name: "Ubah Password",
            to: "/akun_dosen/profilku/ubahpassword",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Riwayat Login",
        to: "/akun_dosen/riwayatlogin",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Nilai Dosen",
    to: "/nilaidosen",
    items: [
      {
        component: "CNavItem",
        name: "Beranda_Nilai",
        to: "/nilaidosen/berandanilai",
      },
      {
        component: "CNavItem",
        name: "Riwayat Input Nilai",
        to: "/nilaidosen/riwayatinput",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Registrasi Dosen",
    to: "/registrasidosen",
    items: [
      {
        component: "CNavItem",
        name: "Beranda",
        to: "/registrasidosen/berandaregdosen",
      },
      {
        component: "CNavGroup",
        name: "Jadwal",
        to: "/registrasidosen/jadwal",
        items: [
          {
            component: "CNavItem",
            name: "Lihat Jadwal Mahasiswa Keseluruhan",
            to: "/registrasidosen/jadwal/lihatjadwal",
          },
          {
            component: "CNavItem",
            name: "Rencana Mengajar Dosen",
            to: "/registrasidosen/jadwal/rencanamengajar",
          },
          {
            component: "CNavItem",
            name: "Lihat Jadwal Ujian",
            to: "/registrasidosen/jadwal/lihatjadwalujian",
          },
          {
            component: "CNavItem",
            name: "Jadwal Ujian Mahasiswa",
            to: "/registrasidosen/jadwal/jadwalujianmahasiswa",
          },
          {
            component: "CNavItem",
            name: "Jadwal Dosen",
            to: "/registrasidosen/jadwal/jadwaldosen",
          },
          {
            component: "CNavItem",
            name: "Kalender Akademik",
            to: "/registrasidosen/jadwal/kalenderak",
          },
        ],
      },
    ],
  },
  {
    name: "Kurikulum Dosen",
    to: "/kurikulumdosen",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Dosen",
        to: "/kurikulumdosen/berandadosen",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "PPM",
    to: "/ppm",
    items: [
      {
        component: "CNavGroup",
        name: "Acara",
        to: "/ppm/acara",
        items: [
          {
            component: "CNavItem",
            name: "Jurnal Saya",
            to: "/ppm/acara/jurnalsaya",
          },
          {
            component: "CNavItem",
            name: "Penelitian",
            to: "/ppm/acara/penelitian",
          },
        ],
      },
      {
        component: "CNavGroup",
        name: "Dokumen",
        to: "/ppm/dokumen",
        items: [
          {
            component: "CNavItem",
            name: "Dokumen Pendukung",
            to: "/ppm/dokumen/dokumenpendukung",
          },
        ],
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "TA/PA Dosen",
    to: "/tapa_dos",
    items: [
      {
        component: "CNavItem",
        name: "Beranda TA/PA Dosen",
        to: "/tapa_dos/beranda",
      },
      {
        component: "CNavItem",
        name: "Daftar Mahasiswa Bimbingan yang Sudah Sidang",
        to: "/tapa_dos/daftarmahasiswabimbingansudahsidang",
      },
      {
        component: "CNavItem",
        name: "Daftar Mahasiswa Diuji",
        to: "/tapa_dos/daftarmahasiswadiuji",
      },
      {
        component: "CNavItem",
        name: "Daftar Mahasiswa Bimbingan",
        to: "/tapa_dos/daftarmahasiswabimbingan",
      },
      {
        component: "CNavItem",
        name: "Jadwal TA/PA Mahasiswa",
        to: "/tapa_dos/jadwaltapamahasiswa",
      },
      {
        component: "CNavItem",
        name: "Referensi",
        to: "/tapa_dos/referensi",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Presensi",
    to: "/presensi-dosen/bap",
    items: [
      {
        component: "CNavGroup",
        name: "Kehadiran Mahasiswa",
        to: "/presensi-dosen/kehadiranmhs",
        items: [
          {
            component: "CNavItem",
            name: "Kehadiran per Perkuliahan",
            to: "/presensi-dosen/kehadiranmhs/per-perkuliahan",
          },
        ],
      },
      {
        component: "CNavItem",
        name: "Jadwal Dosen",
        to: "/presensi-dosen/jadwaldosen",
      },
      {
        component: "CNavItem",
        name: "Berita Acara Perkuliahan",
        to: "/presensi-dosen/bap",
      },
      {
        component: "CNavItem",
        name: "Cetak Daftar Hadir",
        to: "/presensi-dosen/cetakdaftarhadir",
      },
      {
        component: "CNavItem",
        name: "Evaluasi Pembelajaran",
        to: "/presensi-dosen/evaluasipembelajaran",
      },
      {
        component: "CNavItem",
        name: "Honor Kehadiran",
        to: "/presensi-dosen/honor",
      },
    ],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
